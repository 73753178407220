
import { Vue, Component } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";
import {
  mdiAutorenew,
  mdiDotsVertical,
  mdiFolder,
  mdiFolderOpen,
  mdiMagnify,
  mdiPen,
  mdiPlusCircleOutline,
  mdiTrashCan
} from "@mdi/js";
import axios from "axios";
@Component
export default class Home extends Vue {
  $message!:any
  dataSource: any = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 15,
    },
    className: "currency",
  });

  icons: any = {
    pen: mdiPen,
    trash: mdiTrashCan,
    search: mdiMagnify,
    dotsVertical: mdiDotsVertical,
    circlePlus: mdiPlusCircleOutline,
    folderOpen: mdiFolderOpen,
    folder: mdiFolder,
    refresh: mdiAutorenew,
  };

  headers: Array<any> = [
    {
      text: "ID",
      value: "id",
      sortable: true,
      width: 100,
    },
    {
      text: "Наименование",
      value: "name",
      sortable: true,
    },
    {
      text: "Код (Символ)",
      value: "isoLetterCode",
      sortable: false,
    },
    {
      text: "Код (Число)",
      value: "isoNumCode",
      sortable: false,
    },
    {
      text: "Статус",
      value: "instanceStateId",
      sortable: false,
    },
  ];

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Валюты",
        disabled: true,
      },
    ];
  }

  async loadData(){
    await axios.post("manage/currency/import")
    await this.dataSource.get();
  }


}
